import React from "react"
import Layout from "../../components/layouts/Default"
import Container from "../../components/common/Container"
import H2 from "../../components/typography/H2"
import P from "../../components/typography/P"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Newsletter abgemeldet" desc="">
      <Container>
        <div className="max-w-2xl mx-auto">
          <H2 className="mb-4">Vielen Dank für Ihr Vertrauen!</H2>
          <P className="mb-2">
            Wir haben Dich von unserem Newsletter abgemeldet.{" "}
          </P>
        </div>
      </Container>
    </Layout>
  )
}
